import { status } from 'skin/event/status';
import act from './act';

const vCmp = (obj, table, story, d, action) => {
	console.log('[Клиенты флет] событие', obj)
	switch (obj.type) {
		case 'action':
			return act(obj, table, story, d, action);
		default:
			return status.action;
	}
}
export default vCmp
