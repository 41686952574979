import { observer } from "mobx-react-lite";
import { useContext } from 'react';
import { Cnt } from 'skin/context';
import m from "skin/store/mb";
import './style.css';

const View = props => {
	let cls = [
		'skin-tbl-head-com-view',
		'tbl-head-com-view'
	]
	const mb =  m.isMobile;
	const func = props.data.func?.on
	const {action} = useContext(Cnt)
	if (func) cls.push('off')
	cls = cls.join(' ')
	const view = props.data.view
	const st = {backgroundImage: 'url(skin/img/tb_view.svg)'}
	return (
		<>
			{!mb && <div className={cls} style={st} data-info={view.code} data-info-title='Представление' onClick={open}>
				{view.title}
			</div>}
			{mb && <div className=''>{view.title}</div>}
		</>
		
	)
	// Открыть список представлений
	function open() {
		if (func) return
		const o = {
			type: 'view',
			action: 'open'
		}
		action(o)
	}
}

export default observer(View)
