import {info} from 'skin/event'
import Main from './main'
import './style.css'
import { observer } from 'mobx-react-lite'
import m from 'skin/store/mb'

const Content = props => {
	let cls = [
		'skin-info-content',
		'info-content'
	]
	cls = cls.join(' ')
	const mb = m.isMobile;
	if(mb) cls+= ' mb-info-content'
	const st = {backgroundImage: 'url(skin/img/close.svg)'}
	const d = props.data;
	return (
		<div className={cls}>
			<h1 className='info-content-title'>Техническая информация</h1>
			{!mb && <Main data={props.data}/>}
			{mb && 
			<div className='mb-info-content-main'>
				{d.map((el, i) => {
					const elem = el.title 
					? 
					<div className='mb-info-content-item'>
							<div className='mb-info-content-item-title'>{el.title}</div>
							<div className='mb-info-content-item-value'>{el.value}</div>
					</div>
					:
					<div className='mb-info-content-item'>
							<div className='mb-info-content-item-topic'>{el.value}</div>
					</div>
		
					return elem;
				})}
			</div>
			}
			<div className='info-cnt-close' style={st} onClick={close}></div>
		</div>
	)
}
// Закрыть окно
function close() {
	info()
}

export default observer(Content)
