import { status } from 'skin/event/status';
import request from '../fn';

const primary = (obj, ms, story, data, d, action) => {
	const hex = obj.value
	request(hex)
	.then(r => {
		const {light, dark} = r
		data.setValue('txtPrimary', light.color)
		data.setValue('onPrimary', light.onColor)
		data.setValue('primaryContainer', light.colorContainer)
		data.setValue('onPrimaryContainer', light.onColorContainer)
		data.setValue('darkPrimary', dark.color)
		data.setValue('darkOnPrimary', dark.onColor)
		data.setValue('drkPrimaryCont', dark.colorContainer)
		data.setValue('drkOnPrimaryDrk', dark.onColorContainer)
	})
	.catch(err => console.log('err', err))
	return status.action
};

export default primary;
