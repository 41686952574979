import api from 'skin/http';

const capitalize = (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase();
function company(id, setCompany, setError) {
	id = id.trim()
	const opt = {
		method: 'GET',
		url: 'api/other/signup/company/' + id,
		headers: {
			nowait: true
		}
	}
	api(opt)
	.then(r => {
		const d = r.data
		if (!d) return setError('Не удалось действующую компанию по данному ИНН.')
		const postName = d.data.management?.post;
		const role = postName ? capitalize(postName) : 'Руководитель';
		const kl = d.data.address.data
		const manager = d.data.type === 'INDIVIDUAL' ? `${d.data.fio.surname} ${d.data.fio.name} ${d.data.fio.patronymic}` : d.data.management?.name;
		const data = {
			type: d.data.type,
			inn: d.data.inn,
			offName: d.value,
			manager: manager,
			managerTitle: role,
			ogrn: d.data.ogrn,
			kpp: d.data.kpp,
			kladrId: kl.city_kladr_id ?? kl.settlement_kladr_id,
			address: d.data.type === 'LEGAL' ? d.data.address?.unrestricted_value : '',
		};

		setCompany(data);
	})
	.catch(err => {
		console.log('err', err)
	})
}

export default company