import { message, space } from "skin/event"
import { status } from "skin/event/status"


function msAct(obj, table, story, d, action) {
	const id = story.s.vCmp.key
	console.log('id', id)
	if (!id) {
		message('Укажите клиента', 'Формирование акта оказания услуг', null, '!')
		return status.ignore
	}
	const o = {
		type: 'master',
		// code: 'msAct',
		title: 'Акт оказания услуг',
		data: {
			code: 'msAct',
			info: [id],
			type: 'table'
		}
	}
	space(o, true)
	return status.ignore
}

export default msAct