import { message } from 'skin/event';
import { status } from 'skin/event/status';
import user from 'skin/store/user';

const setImg = (obj, form, story, data, d, action) => {
	const partnerId = data.s.values.fld.partnerId
	if(partnerId && user.code === 'grilan') {
		message(`Редактирование партнерского товара не активно`, 'Продукты');
		return status.ignore
	}
};

export default setImg;
