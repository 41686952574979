import { status } from 'skin/event/status';
import msAct from './ms_act';

const act = (obj, table, story, d, action) => {
	console.log('act', obj)
	switch(obj.id) {
		case '67618975b4d0b8001d5cb69b': return msAct(obj, table, story, d, action)
	}
	return status.action
}

export default act