import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { message } from 'skin/event';
import api from 'skin/http';

class Data {
	s = {
		values: {
			fld: {},
			table: {},
			img: {},
			file: {},
		},
		errors: {},
		changed: [],
	};
	constructor(props) {
		makeAutoObservable(this);
	}

	findRow(id, tbl) {
		if (!id && !tbl) return null;
		const t = this.s.values.table[tbl];
		if (!t) return null;
		const o = t.list.find((e) => e.key === id);

		return o;
	}
	// Записываем значение для опрределенного id
	setValue(key, value, action) {
		if (!this.s.changed.includes(key)) this.s.changed.push(key);

		// Добавляем новый объект в values
		this.s.values.fld[key] = value;

		// Удаляем ошибки
		this.s.errors[key] = null;
		if (!action) return
		const o = {
			type: 'data',
			action: 'setEnd',
			value,
			name: key
		}
		action(o)
	}

	// Записываем картинку
	setImg(key, fd) {
		if (!this.s.changed.includes(key)) this.s.changed.push(key);

		const v = this.s.values;
		// Удаление Изображения
		if (!fd) {
			v.fld[key] = '';
			delete v.img[key];
			return;
		}
		// Выбор нового Изображения
		v.fld[key] = 'new';
		const f = fd.get(key);
		v.img[key] = {
			name: f.name,
			size: f.size,
			mimeType: f.type,
			type: f.name.split('.').pop(),
			file: fd,
		};
	}

	// Записываем файл
	setFile(key, fd) {
		if (!this.s.changed.includes(key)) this.s.changed.push(key);

		const v = this.s.values;
		// Удаление Файл
		if (!fd) {
			v.fld[key] = '';
			delete v.file[key];
			return;
		}
		// Выбор нового файла
		v.fld[key] = 'new';
		const f = fd.get(key);
		v.file[key] = {
			name: f.name,
			size: f.size,
			mimeType: f.type,
			type: f.name.split('.').pop(),
			file: fd,
		};
	}

	// Есть ли не сохраненные изменения в форме
	isChanged() {
		const changed = this.s.changed;
		return changed.length ? true : false;
	}

	// Записываем ошибки
	setError(err) {
		this.s.errors = err;
	}

	// Запуск функции на исполнение
	action(n) {
		const obj = this.s.func ?? {};
		let o = obj.list[n];
		if (obj.current !== o.id) {
			obj.current = o.id;
			return;
		}
		if (!o.action) {
			alert('Действие не определено');
			return;
		}
		o.action.title = o.action.title ?? o.title;
		o = toJS(o.action);
	}

	// Получение всех данных по структуре для валидации
	getAll(form, check = false) {
		const fld = form.s.fld;
		const s = this.s;
		const d = s.values.fld;
		const image = s.values.img;
		const file = s.values.file;

		const o = {
			id: {},
			all: {},
			date: {},
			time: {},
			img: {},
			file: {},
			txt: {}
		};

		fld.forEach((el) => {
			const key = el.name;
			switch (el.type) {
				case 'id':
					o.id[key] = d[key];
					break;
				case 'number':
					o.all[key] = +d[key];
					break;
				case 'bool':
					o.all[key] = d[key];
					break;
				case 'date':
					o.date[key] = d[key];
					break;
				case 'time':
					o.time[key] = d[key];
					break;
				case 'txt':
					o.txt[key] = d[key];
					break;
				case 'img':
					o.all[key] = d[key];
					if (image[key]) {
						o.img[key] = {
							mimeType: image[key].mimeType,
							name: image[key].name,
							size: image[key].size,
							type: image[key].type,
						};
					}
					break;
				case 'file':
					o.all[key] = d[key];
					if (file[key]) {
						o.file[key] = {
							mimeType: file[key].mimeType,
							name: file[key].name,
							size: file[key].size,
							type: file[key].type,
						};
					}
					break;
				default:
					o.all[key] = d[key];
					break;
			}
		});

		// Возвращям Объекты без файлов
		if (!check) return o;

		let formData = new FormData();

		for (let key in image) {
			const img = image[key];
			if (img?.file && img.file instanceof FormData) {
				formData.append(key, img.file.get(key));
			}
		}
		for (let key in file) {
			const f = file[key];
			if (f?.file && f.file instanceof FormData) {
				formData.append(key, f.file.get(key));
			}
		}

		for (let key in o) {
			formData.append(key, JSON.stringify(o[key]));
		}
		return formData;
	}

	// Получение измененых данных по структуре
	getChanged(form) {
		const fld = form.s.fld;
		const s = this.s;
		const d = s.values.fld;
		const image = s.values.img;
		const file = s.values.file;

		let formData = new FormData();

		for (let key in image) {
			const img = image[key];
			if (img?.file && img.file instanceof FormData) {
				formData.append(key, img.file.get(key));
			}
		}

		for (let key in file) {
			const f = file[key];
			if (f?.file && f.file instanceof FormData) {
				formData.append(key, f.file.get(key));
			}
		}

		const o = {
			id: {},
			all: {},
			date: {},
			time: {},
			dt: {},
			img: {},
			file: {},
			txt: {},
			update: d.update ?? '',
		};

		s.changed.forEach((el) => {
			const f = fld.find((e) => e.name === el);

			switch (f.type) {
				case 'id':
					o.id[el] = d[el];
					break;
				case 'number':
					o.all[el] = +d[el];
					break;
				case 'bool':
					o.all[el] = d[el];
					break;
				case 'date':
					o.date[el] = d[el];
					break;
				case 'time':
					o.time[el] = d[el];
					break;
				case 'txt':
					o.txt[el] = d[el];
					break;
				case 'img':
					o.all[el] = d[el];
					// Удаление
					if (d[el] === '') {
						o.img[el] = true;
						break;
					}
					// Добавление
					if (image[el]) {
						o.img[el] = {
							mimeType: image[el].mimeType,
							name: image[el].name,
							size: image[el].size,
							type: image[el].type,
						};
					}
					break;
				case 'file':
					o.all[el] = d[el];
					// Удаление
					if (d[el] === '') {
						o.file[el] = true;
						break;
					}
					// Добавление новго
					if (file[el]) {
						o.file[el] = {
							mimeType: file[el].mimeType,
							name: file[el].name,
							size: file[el].size,
							type: file[el].type,
						};
					}
					break;
				default:
					o.all[el] = d[el];
					break;
			}
		});

		for (let key in o) {
			formData.append(key, JSON.stringify(o[key]));
		}
		return formData;
	}

	addRow(form, tbl, row, key = null) {
		if (!row.key) return message(`Выберите значение строки`, form.bookTitle);
		const s = this.s;
		const t = s.values?.table[tbl];
		const i = key ? t.list.findIndex((e) => e.key === key) : t.list.length;
		if (i === -1) return;
		const j = t.list.findIndex((e) => e.key === row.key);
		if (j > -1)
			return message(`Данная строка уже существует`, form.bookTitle);
		const a = [...t.list];
		a.splice(i, 0, row);
		// перезаписываем новый лист
		t.list = a;
	}

	// Получение данных с сервера
	getData(d, action, form, story, tbl) {
		let v = JSON.parse(d);
		d = {
			info: v.info,
			// Получение сортировки по данным из структуры
			table: getSort(form.s.table, story.current.table),
		};
		d = JSON.stringify(d);

		const self = this;
		api.get(`api/data/form/${v.code}`, { params: { value: d } })
			.then(function (response) {
				const r = response.data.result;
				runInAction(() => {
					// очищаем список измененых полей
					self.s.changed = [];
					self.s.errors = {};

					if (tbl) {
						self.s.values.table[tbl] = r?.table[tbl] ?? [];
					} else {
						// получаем поля
						if (!r?.fld)
							console.log('getData - Нет данных по полям');
						else {
							for (let key in r?.fld) {
								self.s.values.fld[key] = r.fld[key];
							}
						}

						// получаем таблицы
						if (!r?.table)
							console.log('getData - Нет данных по таблицам');
						else {
							for (let key in r.table) {
								self.s.values.table[key] = r.table[key];
							}
						}
					}
					const o = {
						type: 'form',
						action: 'loadData',
					};

					action(o);
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	// формируем пустые данные
	defData(action, form) {
		this.def();
		const values = this.s.values;
		const err = this.s.errors;
		if (form.s.fld) {
			form.s.fld.forEach((el) => {
				// Добавляем новый пустой объект в values
				values.fld[el.name] = '';
				err[el.name] = null;
			});
		}

		const o = {
			type: 'form',
			action: 'defData',
		};

		action(o);
	}

	//Формирование пустой структуры
	def() {
		this.s = {
			values: {
				fld: {},
				table: {},
				img: {},
				file: {},
				txt: {},
			},
			errors: {},
			changed: [],
		};
	}
}

function getSort(tbl, pg) {
	const o = {};

	tbl.forEach((e) => {
		// Выбираем колонки с сортировкой
		let s = e.col.filter((el) => el.sort);
		// Сортируем поля по модулю
		s = s.sort((a, b) => Math.abs(a.sort) - Math.abs(b.sort));

		const sort = {};
		s.forEach((el) => {
			if (el.sort > 0) sort[el.name] = 1;
			else sort[el.name] = -1;
		});

		let p = 1;
		if (pg) {
			p = pg[e.code]?.page;
		}

		o[e.code] = { sort, page: p };
	});
	return o;
}

const data = new Data();
export default data;